import { useEffect } from 'react'
import axios from 'axios'
import { initDunamis } from '../analytics'
import { sendLoginAnalytics } from '../helpers/analytics-helpers'
import ContactCenter from '../Apps/ContactCenter/Modules/ContactCenter'
import config from '../config/env_variables'
import { AGENT_DEFAULT_STATUS } from '../Apps/ContactCenter/Constants'

const agentDefaultStatus = {
  status: AGENT_DEFAULT_STATUS
}

const useOnLoad = (oktaAuth: any) => {
  const onUnload = (e: any) => {
    ContactCenter.saveActiveChatIds()
    const agentState: any = ContactCenter.getAgentStatus()
    sessionStorage.setItem('agent_currentStatus', agentState.currentState)
    sessionStorage.setItem('agent_nextStatus', agentState.nextState)
    ContactCenter.updateAgentStatus(agentDefaultStatus.status, '')
    const close = ContactCenter.isLogoutAllowed()

    if (!close) {
      e.preventDefault()
      e.returnValue = 'Are you sure you want to leave?'
      setTimeout(() => {
        const previousStatus = sessionStorage.getItem('agent_currentStatus')
        let nextStatus = sessionStorage.getItem('agent_nextStatus')
        if (previousStatus) {
          nextStatus = nextStatus || ''
          ContactCenter.updateAgentStatus(previousStatus, nextStatus)
        }
      }, 1000)
    }
    // ContactCenter.closeTab()
  }
  const onLoad = () => {
    if (Notification.permission !== 'granted') Notification.requestPermission()
  }

  useEffect(() => {
    let profileInfo = {
      name: '',
      email: '',
      preferred_username: ''
    }
    // initiate dunamis
    initDunamis()
    window.addEventListener('beforeunload', onUnload)
    window.addEventListener('load', onLoad)

    const getOktaInfo = async () => {
      try {
        sendLoginAnalytics({})

        const OktaData = await oktaAuth.getUser()
        sendLoginAnalytics({
          subCategory: 'Implicit',
          type: 'success'
        })
        if (OktaData) {
          profileInfo = { ...profileInfo, ...OktaData }
          const oktaToken: any = JSON.parse(localStorage.getItem('okta-token-storage') || '')
          if (oktaToken?.accessToken?.accessToken) {
            const {
              data: { data: { json: { accessToken = '', customPanelAccessToken = '', fastFollowFlags = '', prodCustomPanelToken = '' } = {} } = {} }
            }: any = await axios.get(`${config.CCP_CH_URL}/api/authenticate`, {
              params: {
                accessToken: oktaToken?.accessToken?.accessToken
              },
              headers: { 'x-api-key': 'oac-connect-service' }
            })
            localStorage.setItem('ims-token', JSON.stringify(accessToken))
            localStorage.setItem('customPanelAccessToken', JSON.stringify(customPanelAccessToken))
            localStorage.setItem('fastFollowFlags', JSON.stringify(fastFollowFlags))
            localStorage.setItem('prodCustomPanelToken', JSON.stringify(prodCustomPanelToken))
            new ContactCenter(profileInfo)
          }
        } else {
          sendLoginAnalytics({
            subCategory: 'Implicit',
            type: 'failure'
          })
        }
      } catch (error) {
        console.log('useOnLoad error ', error)
        sendLoginAnalytics({
          subCategory: 'Implicit',
          type: 'failure'
        })
      }
    }
    getOktaInfo()

    return () => {
      window.removeEventListener('beforeunload', onUnload)
      window.removeEventListener('load', onLoad)
    }
  }, [oktaAuth])
}

export default useOnLoad
