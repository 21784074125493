import store from '../redux/store';
import ContactCenter from '../Apps/ContactCenter/Modules/ContactCenter';

declare const window: any;

interface CurrentConversationDetailsObj {
    currentContactId: string;
    initialContactId: string;
    queue: string;
    origin: string;
    ldap: string;
    email: string;
}

/**
 * This function will fetch conversation details for a particular contact
 * whether it is close or active
 * @param contactId 
 */
export function fetchConversationDetails(contactId) {
    const { conversations } = store.getState().contactCenter;
    const contactDetails = conversations?.find(item => {
        // As per the current requirement, CTR leg level analytics applicable for active conversation.
        if (item?.ocPlatformData?.chatInfo?.currentContactId === contactId) {
            return item;
        }
    });

    return contactDetails || null;
}

/**
 * This function will fetch conversation details for an active contact
 * @param contactId 
 * @returns 
 */
export function fetchActiveAndCurrentConversationData(contactId) {
    const { conversations } = store.getState().contactCenter;
    const activeAndCurrentConversation = conversations.find(item => {
        // As per the current requirement, CTR leg level analytics applicable for active conversation.
        if (item.ocPlatformData.chatInfo.currentContactId === contactId && item.ocPlatformData.chatInfo.status !== 'Closed') {
            return item;
        }
    });

    return activeAndCurrentConversation || undefined;
}

/**
 * 
 * currentContact ID  is not valid only for below scenario
 * For chat to Phone
 * Agent 1 : Phone current contact ID read from Call info. currentcontactId
 * Chat Current contact Id will be available in chat Info
 * With respect to conversation we need read from chat info
 * 
 * Agent 2 Post transfer Voice leg
 * Phone current contact ID read from Call info. currentcontactId
 * Chat Current contact Id will be available in chat Info
 * With respect to conversation we need read from linkedConversationId from JC auth data, Please use the function : getConversationIdIncaseOfChatToPhone
 * @returns 
 */
export function getCurrentConvDetails(): CurrentConversationDetailsObj {
    const {
        contactCenter: {
            currentConversation: {
                ocPlatformData: { agentInfo: { agentLDAP = '', agentID = '' } = {}, chatInfo: { status: chatStatus = '', currentContactId: chatCurrentContactId = '', initialContactId = '' } = {}, callInfo: { origin: callOrigin = '', status: callStatus = '', currentContactId: callCurrentContactId = '' } = {} } = {},
                jcAuthData: { queueName = "", currentQueue = "", origin: chatOrigin = '' } = {},
            } = {},
        } = {},
    }: any = store.getState();

    let currContactId = ''
    const origin = (typeof chatOrigin === "object" ? chatOrigin.value : chatOrigin) || (typeof callOrigin === "object" ? callOrigin.value : callOrigin);
    const agentActiveContacts = ContactCenter.getAllActiveContactStatus();

    // console.log('agentActiveContacts', agentActiveContacts)

     if(origin === 'chat-to-phone') {
        const currentChatContact = agentActiveContacts.find(contact => contact?.contactId === chatCurrentContactId)
        const currentPhoneContact = agentActiveContacts.find(contact => contact?.contactId === callCurrentContactId)

        currContactId = typeof currentChatContact === "object" ? currentChatContact.contactId : currentPhoneContact?.contactId
    } else {
        currContactId = chatCurrentContactId || callCurrentContactId;
    }

    return {
        origin,
        email: agentID || window.dunamis_analytics?.user?.ldap,
        ldap: agentLDAP || window.dunamis_analytics?.user?.email,
        queue: currentQueue?.value || '',
        currentContactId: currContactId || '',
        initialContactId: initialContactId || '',
    }
}

/**
 * Get the  conversation Id when origin is chat to phone
 */
export function getConversationIdIncaseOfChatToPhone(origin: string, channel: string, contactId: string, linkedConversationId: string) {
    const chatToPhoneNewLeg: boolean = origin === 'chat-to-phone' && channel !== 'messaging';
    if (chatToPhoneNewLeg && linkedConversationId)
        return linkedConversationId
    return contactId
}

/**
 * Get the current conversation Id
 */
export function getCurrentConvID(): string {
    return getCurrentConvDetails().currentContactId;
}

/**
 * Get the initial conversation Id
 */
export function getInitialConvId(): string {
    return getCurrentConvDetails().initialContactId;
}

/**
 * Get the current conversation queue
 */
export function getCurrentQueue(): string {
    return getCurrentConvDetails().queue;
}
