import { fetchActiveAndCurrentConversationData, getCurrentConvID, fetchConversationDetails } from '../utils/conversation-utils'
import { updateContactAttributes } from '../Apps/ContactCenter/Api/api';

const LOCAL_STORE_NAME = 'ctr_updates';

/**
 * @param contactId 
 * @param summary 
 * @param updateContactAttributes 
 * 
 * Sample format:
 * =============
 *  "TRANSFER_LEG_ORDER": "CONTACTID_1; CONTACTID_2; CONTACTID_3",
 *  "SUMMARY_ELIGIBLE": "CONTACTID_1: Yes; CONTACTID_2: Yes; CONTACTID_3: No",
 *  "SUMMARY_GENERATED": "CONTACTID_1: Yes; CONTACTID_2: Yes; CONTACTID_3: No",
 *  "SUMMARY_USEFUL": "CONTACTID_3: Yes; CONTACTID_1: No",
 */
export async function updateCTRForConversationSummaryAnalytics(contactId, summary = "", isAgentProvidedFeedback, thumps_up_down) {
    const attributes = {};
    const activeAndCurrentConversation = fetchActiveAndCurrentConversationData(contactId);
    const { jcAuthData } = activeAndCurrentConversation || {};
    const {
        TRANSFER_LEG_ORDER: transferLegOrder,
        SUMMARY_ELIGIBLE: summaryEligible,
        SUMMARY_GENERATED: summaryGenerated,
        SUMMARY_USEFUL: summaryUseful,
    } = jcAuthData || {};

    if (!activeAndCurrentConversation) return;

    // Check whether the conversation Id is already there in the each property ? if so don't update the record
    const isTransferLegOrderUpdated = transferLegOrder?.value?.includes(contactId) || false;
    const isSummaryEligibleFlagUpdated = summaryEligible?.value?.includes(contactId) || false;
    const isSummaryGeneratedFlagUpdated = summaryGenerated?.value?.includes(contactId) || false;

    if (!isTransferLegOrderUpdated) {
        attributes["TRANSFER_LEG_ORDER"] = transferLegOrder
            ? `${transferLegOrder.value}; ${contactId}`
            : contactId;
    }

    if (!isSummaryEligibleFlagUpdated) {
        attributes['SUMMARY_ELIGIBLE'] = summaryEligible
            ? (`${summaryEligible.value}; ${contactId}: ${!!summary}`)
            : (`${contactId}: ${!!summary}`);
    }

    if (!isSummaryGeneratedFlagUpdated) {
        attributes['SUMMARY_GENERATED'] = summaryGenerated
            ? (`${summaryGenerated.value}; ${contactId}: ${!!summary}`)
            : (`${contactId}: ${!!summary}`);
    }

    if (isAgentProvidedFeedback) {
        attributes['SUMMARY_USEFUL'] = summaryUseful
            ? (`${summaryUseful.value}; ${contactId}: ${thumps_up_down}`)
            : (`${contactId}: ${thumps_up_down}`);
    }

    try {
        await updateContactAttributes({
            attributes,
            contactId
        });
    } catch (e) {
        console.log("Failed to update CTR on updateCTRForConversationSummaryAnalytics: ", e);
    }
};

export async function updateCTRForAdobeAnswersAnalytics(showAdobeAnswers) {
    const attributes = {};
    const contactId = getCurrentConvID();
    // const { showAdobeAnswers } = store.getState().contactCenter;
    const activeAndCurrentConversation = fetchActiveAndCurrentConversationData(contactId);
    const { jcAuthData } = activeAndCurrentConversation || {};
    const {
        ADOBE_ANSWERS_ELIGIBLE: adobeAnswersEligible,
    } = jcAuthData || {};

    if (!activeAndCurrentConversation) return;

    // Check whether the conversation Id is already there in the each property ? if so don't update the record
    const isAdobeAnswersEligibleFlagUpdated = adobeAnswersEligible?.value?.includes(contactId) || false;

    if (!isAdobeAnswersEligibleFlagUpdated) {
        attributes['ADOBE_ANSWERS_ELIGIBLE'] = adobeAnswersEligible
            ? (`${adobeAnswersEligible.value}; ${contactId}: ${showAdobeAnswers}`)
            : (`${contactId}: ${showAdobeAnswers}`);
    }

    try {
        await updateContactAttributes({
            attributes,
            contactId
        });
    } catch (e) {
        console.log("Failed to update CTR on updateCTRForAdobeAnswersAnalytics: ", e);
    }
};

function getCTRItem(id?: string) {
    const item = sessionStorage.getItem(`${LOCAL_STORE_NAME}_${id}`);

    return JSON.parse(item);
}

function updateCTRItem(id: string, newItem) {
    const item = sessionStorage.getItem(`${LOCAL_STORE_NAME}_${id}`);
    const updatedItem = { ...JSON.parse(item), ...newItem };

    if (!item) {
        addCTRItem(newItem, id);
        return;
    }
    sessionStorage.setItem(`${LOCAL_STORE_NAME}_${id}`, JSON.stringify(updatedItem));
    return;
}

function addCTRItem(item, id?) {
    const strigifiedItem = JSON.stringify(item);

    sessionStorage.setItem(`${LOCAL_STORE_NAME}_${id}`, strigifiedItem);
    return;
}

function removeCTRItem(id?: string) {

    sessionStorage.removeItem(`${LOCAL_STORE_NAME}_${id}`);
    return;
}

/** Send the sessionStorage CTR data during onEnd function chat/voice*/
async function sendCTRItemToAmazonConnect(id: string) {
    const currentConversationDetails = fetchConversationDetails(id);
    const { jcAuthData } = currentConversationDetails || {};
    const dbItem = getCTRItem(id);
    const {
        vaoEligibilityFlag,
        consentSend,
        consentReceived,
        offerPitchFlag,
        offerAcceptedFlag,
        customerSearchClick,
        offerId,
        valueAddActionSales,
        valueAddActionSupport,
        RTCS_ContactSentiment,
        RTCS_BotSentiment,
        RTCS_EligibilityFlag
    } = jcAuthData || {};
    const attributes = {};
    const prevLegOfferId = (offerId?.value && dbItem['offerId']) ? `${offerId.value}; ` : (offerId?.value) || '';
    const prevLegConsentSend = (consentSend?.value && dbItem['consentSend']) ? `${consentSend.value}; ` : (consentSend?.value) || '';
    const prevLegValueAddActionSupport = (valueAddActionSupport?.value && dbItem['valueAddActionSupport']) ? `${valueAddActionSupport.value}; ` : (valueAddActionSupport?.value) || '';
    const prevLegOfferPitchFlag = (offerPitchFlag?.value && dbItem['offerPitchFlag']) ? `${offerPitchFlag.value}; ` : (offerPitchFlag?.value) || '';
    const prevLegConsentReceived = (consentReceived?.value && dbItem['consentReceived']) ? `${consentReceived.value}; ` : (consentReceived?.value) || '';
    const prevLegOfferAcceptedFlag = (offerAcceptedFlag?.value && dbItem['offerAcceptedFlag']) ? `${offerAcceptedFlag.value}; ` : (offerAcceptedFlag?.value) || '';
    const prevLegVaoEligibilityFlag = (vaoEligibilityFlag?.value && dbItem['vaoEligibilityFlag']) ? `${vaoEligibilityFlag.value}; ` : (vaoEligibilityFlag?.value) || '';
    const prevLegCustomerSearchClick = (customerSearchClick?.value && dbItem['customerSearchClick']) ? `${customerSearchClick.value}; ` : (customerSearchClick?.value) || '';
    const prevLegValueAddActionSales = (valueAddActionSales?.value && dbItem['valueAddActionSales']) ? `${valueAddActionSales.value}; ` : (valueAddActionSales?.value) || '';
    const prevRTSEligibilityFlag = (RTCS_EligibilityFlag?.value && dbItem['RTCS_EligibilityFlag']) ? `${RTCS_EligibilityFlag.value}; ` : (RTCS_EligibilityFlag?.value) || '';
    const prevContactSentiment = (RTCS_ContactSentiment?.value && dbItem['RTCS_ContactSentiment']) ? `${RTCS_ContactSentiment.value}; ` : (RTCS_ContactSentiment?.value) || '';
    // if there is a previous valid bot sentiment, use that, otherwise use the new sentiment if it is valid
    const botSentiment =
    RTCS_BotSentiment?.value && RTCS_BotSentiment?.value?.trim()?.split(':')?.[1]?.length !== 0 && RTCS_BotSentiment?.value?.trim()?.split(':')?.[1] !== '-'
      ? RTCS_BotSentiment?.value
      : dbItem['RTCS_BotSentiment'] && dbItem['RTCS_BotSentiment']?.trim()?.split(':')?.[1]?.length !== 0 && dbItem['RTCS_BotSentiment']?.trim()?.split(':')?.[1] !== '-'
      ? dbItem['RTCS_BotSentiment']
      : ''

    if (dbItem) {
        attributes['offerId'] = prevLegOfferId + (dbItem['offerId'] || '');
        attributes['consentSend'] = prevLegConsentSend + (dbItem['consentSend'] || '');
        attributes['valueAddActionSupport'] = prevLegValueAddActionSupport || dbItem['valueAddActionSupport'] || '';
        attributes['offerPitchFlag'] = prevLegOfferPitchFlag + (dbItem['offerPitchFlag'] || '');
        attributes['consentReceived'] = prevLegConsentReceived + (dbItem['consentReceived'] || '');
        attributes['offerAcceptedFlag'] = prevLegOfferAcceptedFlag + (dbItem['offerAcceptedFlag'] || '');
        attributes['vaoEligibilityFlag'] = prevLegVaoEligibilityFlag + (dbItem['vaoEligibilityFlag'] || '');
        attributes['customerSearchClick'] = prevLegCustomerSearchClick + (dbItem['customerSearchClick'] || '');
        attributes['valueAddActionSales'] = prevLegValueAddActionSales || (dbItem['valueAddActionSales'] || '');
        attributes['RTCS_ContactSentiment'] = prevContactSentiment + (dbItem['RTCS_ContactSentiment'] || '');
        attributes['RTCS_BotSentiment'] = botSentiment ? botSentiment : 'NA';
        attributes['RTCS_EligibilityFlag'] = prevRTSEligibilityFlag + (dbItem['RTCS_EligibilityFlag'] || '');

        try {
            await updateContactAttributes({
                attributes,
                contactId: id
            });
        } catch (e) {
            console.log("Failed to update CTR on updateCTRForConversationSummaryAnalytics: ", e);
        }
    }
}

export { getCTRItem, updateCTRItem, addCTRItem, removeCTRItem, sendCTRItemToAmazonConnect };
