import { getCurrentConvID, getInitialConvId } from '../utils/conversation-utils';
import { updateCTRItem } from './ctr-updates';

export function isRTCSEnabledByContactId(isEnabled) {
    return isEnabled ? 'Yes' : 'No';
}

export function isRTCSContactSentimentCreatedByContactId(obj) {
    return obj?.sentiment || 'NA';
}

export function isRTCSBOTContactSentimentCreatedByContactId(sentiment) {
    return sentiment || 'NA';
}

export function updateRTCSEligibilityFlag(value: string) {
    const currentContactId = getCurrentConvID();

    if (currentContactId === '') return;

    updateCTRItem(currentContactId, { RTCS_EligibilityFlag: `${currentContactId}: ${value}` })
}

export function updateRTCSContactSentiment(value: string) {
    const currentContactId = getCurrentConvID();

    if (currentContactId === '') return;

    updateCTRItem(currentContactId, { RTCS_ContactSentiment: `${currentContactId}: ${value}` })
}

export function updateRTCSBotContactSentiment(value: string) {
    const initialContactId = getInitialConvId();

    updateCTRItem(initialContactId, { RTCS_BotSentiment: `${initialContactId}: ${value}` })
}