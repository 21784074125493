export function isZendeskDecommissioned(){
    try {
        const localData: any = localStorage.getItem('fastFollowFlags')
        return JSON.parse(localData)?.DECOMMISSION_ZENDESK === true
      } catch (e) {
        console.log('fastFollowFlags fail', e)
        return false
      }
}

/**
 * Util method for invoking a callback immediately, after some delay, or/and in regular increments
 * @param callback callback to be invoked
 * @param intervalHandler handler fn to store/clear the interval
 * @param interval the time interval to invoke by. If undefined then no interval is started
 * @param delay the delay for first invocation. Can be 0 for immediate invocation. If undefined then there is no first invocation
 */
export const delayOrInvokeInterval = async (
  callback: () => void,
  intervalHandler: (intervalId: NodeJS.Timeout) => void,
  interval?: number,
  delay?: number
): Promise<void> => {
  if (delay != null) {
    if (delay > 0) {
      await new Promise((resolve, reject) => {
        setTimeout(() => resolve(`Delayed ${delay}ms`), delay)
      })
    }

    callback()
  }

  if (interval != null) {
    const newTimer = setInterval(() => {
      callback()
    }, interval)

    intervalHandler(newTimer)
  }
}
