import { getFeatureFlagsForRTS, getFlagsForConversationSummary, getOACFlags, getFlagsForRU } from '../Apps/ContactCenter/Api/api'

export const ENABLE_SUMMARY = 'EnableSummary'
export const ENABLE_RU = 'EnableRUWidget'
export const RTS_METADATA_KEY = 'oac-rtcs-app-config-rule'
export const ENABLE_MOCK_OFFER_ID = 'enable-mock-offer-id';

export async function enableConvSummaryByQueue(queue) {
    const floodgateResponse: any = await getFlagsForConversationSummary(queue) || {};
    const featureFlags = floodgateResponse?.data?.releases[0]?.features || []
    const isAllowedFromFG = featureFlags?.includes(ENABLE_SUMMARY)
    
    console.log('Conversation Summary Enabled By Queue:', isAllowedFromFG)

    return isAllowedFromFG || false;
}

export async function enableRUByQueue(queue, agentLdap) {
    const floodgateResponse: any = await getFlagsForRU(queue, agentLdap) || {};
    const featureFlags = floodgateResponse?.data?.releases[0]?.features || []
    const isAllowedFromFG = featureFlags?.includes(ENABLE_RU)
    
    console.log('RU Enabled By Queue:', isAllowedFromFG)

    return isAllowedFromFG || false;
}

export async function queryRealTimeSentimentFloodgate(
  ldap: string,
): Promise<{
  featureFlags: Array<any>,
  metadata: {[key: string]: string}
}> {
  try {
    const floodgateResponse: any = await getFeatureFlagsForRTS(ldap) || {};
    const featureFlags = floodgateResponse?.data?.releases[0]?.features || []
    let metadata = floodgateResponse?.data?.releases[0]?.meta?.find((md: any) => md?.k === RTS_METADATA_KEY)?.v || ''
    metadata = atob(metadata) || null
    try {
      metadata = JSON.parse(metadata)
    } catch (e) {
      console.log('Error parsing the metadata from floodgate for real time sentiment')
      metadata = {}
    }

    return {
      featureFlags,
      metadata
    }
  } catch (e) {
    console.log('Error querying floodgate for real time sentiment feature flags.')
    return {
      featureFlags: [],
      metadata: {}
    }
  }
}

export async function enableRealTimeSentimentByQueueAndLdap(
  queue: string,
  ldap: string
): Promise<boolean> {
  const floodgateResponse: any = await getFeatureFlagsForRTS(ldap) || {};
  const featureFlags = floodgateResponse?.data?.releases[0]?.features || []
  const isAllowedFromFG = featureFlags?.includes(queue)

  return isAllowedFromFG || false;
}

export async function enableMockOfferId() {
    const floodgateResponse: any = await getOACFlags() || {};
    const featureFlags = floodgateResponse?.data?.releases[0]?.features || []
    const isAllowedFromFG = featureFlags?.includes(ENABLE_MOCK_OFFER_ID)
    
    console.log('Mock Offer ID enabled :', isAllowedFromFG)

    return isAllowedFromFG || false;
}
