/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LibraryModule as WidgetBar } from 'oc-widget-top-module-client'
import { triggerWidget, closeWidgets, setRuWidget } from '../../../redux/actions/widgetLayoutAction'
import ContactCenter from '../../../Apps/ContactCenter/Modules/ContactCenter'
import { setAutoTranslation, setContactRealTimeSentiment } from '../../../redux/actions/contactCenterActions'
import localization from './lang/localization'
import '../styles.scss'
import 'oc-widget-top-module-client/dist/index.css'
import { CALLEVENTS } from '../../ContactCenter/Constants'
import Utility from '../../ContactCenter/Modules/Utility'
import { useContextData } from '../Hooks'
import { checkAdobeAnswerEligibility, checkRUEligibility } from '../../../redux/actions/CheckEligibilityActions'
import { isZendeskDecommissioned } from '../../../utils/utils'
import { RootState } from '../../../redux/reducers/rootReducer'
import { SALES_CIP, SET_RU_WIDGET_SHOW } from '../../../redux/constants/widgetLayoutConstants'
import { COLLAPSE_RU_DETAILS_HANDLER, COLLAPSE, UNMOUNT_RU_WIDGET_BUTTOM, MOUNT_RU_WIDGET_BUTTOM } from '../Modules/RecomendedUpgradePanel/RUConstants'
import { alertManagerNegativeSentimentApi, fetchConversationOverallSentimentApi } from '../../ContactCenter/Api/transcripts'

const TopBar = () => {
  const dispatch = useDispatch()
  const { widgets, widgetWrapperWidth, widgetStack, ruExpanded, ruWidgetBottom, ruWidgetBottomShow } = useSelector((state: RootState) => state.widgetLayout)
  const {
    currentConversation,
    currentConversationAC,
    currentView,
    autoTranslation = {},
    bannerInfo,
    adobeAnswersEligible,
    agentFeatureTags,
    showAdobeAnswers: showAdobeAnswersReduxState,
    showRUWidget: showRUWidgetState,
    isRUEligible,
    enableRTS,
    contactRTSentiments,
    triggerThresholdRTS,
    previousLegInfo
  } = useSelector((state: RootState) => state.contactCenter)
  const conversation = currentView === 'AC_VIEW' ? currentConversationAC : currentConversation
  const { darkMode, language, agentDetails: { ldap = ''} = {} } = useSelector((state: RootState) => state.preferences)
  const [userWidgets, setUserWidgets] = useState<any[]>([])
  const [negativeTimeElapsed, setNegativeTimeElapsed] = useState<{
    [contactId: string]: number
  }>({})

  const {
    ocPlatformData: {
      chatInfo: { newCustomer: newCustomerInfo = '', initialContactId = '', currentContactId = '', status = '', duration = '', updateDate: updateDateInfo = '' } = {},
      callInfo: { currentContactId: callContactId = '', status: callStatus = '', origin: callOrigin = '', isMute = false, linkedConversationId: callLinkedConversationId = '' } = {}
    } = {},
    jcAuthData: {
      queueName: { value: queueNameVal = '' } = {},
      currentQueue: { value: currentQueueVal = '' } = {},
      language: { value: customerLang = '' } = {},
      origin: { value: jcOrigin = '' } = {},
      channel: { value: channel = '' } = {},
      conversationStartDate: { value: conversationStartDate = '' } = {},
      linkedConversationId: { value: jcLinkedConversationId = '' } = {}
    } = {}
  } = conversation
  const origin = callOrigin || jcOrigin || ''
  const {
    jcAuthData:{customerId = ''},
    ocAppData: { originForCurrentConversation = '', closedConversation = '', closedPhoneCall = '', modifiedCustomer: { rengaId = ""} = {} }
  } = useContextData()
  const phoneOrigin = ['outbound-phone', 'inbound-phone', 'bot-to-phone']
  const isPhoneOrigin = phoneOrigin.includes(originForCurrentConversation)
  const isPhoneOriginOrChatToPhone = isPhoneOrigin || originForCurrentConversation === 'chat-to-phone'
  const isActive = !(closedConversation && closedPhoneCall)
  const isShowAdobeAnswers = !isPhoneOrigin && isActive;
  const showAdobeAnswers = showAdobeAnswersReduxState && adobeAnswersEligible
  const isShowRU = isActive
  const showRUWidget = showRUWidgetState && isRUEligible
  const sentiment = contactRTSentiments[currentContactId]

  useEffect(() => {
    // if conversation is closed and no sentiment exists in redux, fetch the sentiment from the backend
    if (!isActive && initialContactId) {
      fetchConversationOverallSentimentApi(initialContactId)
        .then((res) => {
          const currentContactSentiment = res?.conversationSentimentData?.listOfAllContactIdsSentiment?.find(s => Object.keys(s)?.[0] === currentContactId)
          if (res?.conversationSentimentData?.overallConversationSentiment) {
            dispatch(setContactRealTimeSentiment({
              contactId: currentContactId,
              sentiment: {
                sentiment: res?.conversationSentimentData?.overallConversationSentiment,
                alertState: '',
                timeFirstNegative: 0,
                transcriptSentiment: []
              }
            }))
          } else if (currentContactSentiment) {
            dispatch(setContactRealTimeSentiment({
              contactId: currentContactId,
              sentiment: {
                sentiment: currentContactSentiment[currentContactId]?.sentiment,
                alertState: '',
                timeFirstNegative: 0,
                transcriptSentiment: []
              }
            }))
          }
        })
        .catch((err) => {
          console.log('Error fetching contact sentiment for closed contact: ', err.message ?? 'Internal Error')
        })
    }
  }, [currentContactId, dispatch, isActive, initialContactId])

  useEffect(() => {
    let timer;
    if (
      sentiment?.sentiment === 'NEGATIVE' &&
      sentiment?.timeFirstNegative &&
      sentiment?.alertState !== 'manager'
    ) {
      setNegativeTimeElapsed(timeElapsed => ({
        ...timeElapsed,
        [currentContactId]: Date.now() - (sentiment?.timeFirstNegative ?? 0)
      }))
      timer = setInterval(() => {
        setNegativeTimeElapsed(timeElapsed => ({
          ...timeElapsed,
          [currentContactId]: timeElapsed[currentContactId] + 1000
        }))
      }, 1000)
    } else {
      setNegativeTimeElapsed(timeElapsed => ({
        ...timeElapsed,
        [currentContactId]: null
      }))
    }

    return () => {
      clearInterval(timer)
    }
  }, [currentContactId, sentiment?.alertState, sentiment?.sentiment, sentiment?.timeFirstNegative])

  useEffect(() => {
    if (isShowAdobeAnswers !== showAdobeAnswersReduxState) {
      dispatch({ type: 'SET_SHOW_ADOBE_ANSWERS', payload: isShowAdobeAnswers })
    }
  }, [isShowAdobeAnswers, showAdobeAnswersReduxState])

  useEffect(() => {
    if (isShowRU != showRUWidgetState) {
      dispatch({ type: 'SET_SHOW_RU_WIDGET', payload: isShowRU })
    }

  }, [isShowRU, showRUWidgetState])

  const determineRuBottomTitle = (currentQueueVal: string): string => {
    const element = currentQueueVal.substring(0, 4);
    if (element === 'SLS-' || element === 'DNL-')
      return showAdobeAnswers && channel === 'messaging' ? 'AA' : '';
    if (element === 'ENT-')
      return 'ECM';
    return 'CM';
  };

  useEffect(() => {
    console.log(`widget eligibility: isShowRU - ${isShowRU}, isShowAdobeAnswers - ${isShowAdobeAnswers}`)
    if(isShowRU) {
      dispatch(checkRUEligibility(currentQueueVal, ldap))
      const title = determineRuBottomTitle(currentQueueVal) 
      dispatch(setRuWidget(title))
      if(ruWidgetBottomShow[currentContactId] === undefined) {
        dispatch({ type: SET_RU_WIDGET_SHOW, payload: {convId: currentContactId, ruWidgetBottomShow: true } })
      }
    }
    
    if (isShowAdobeAnswers) {
      dispatch(checkAdobeAnswerEligibility(currentQueueVal))
    }

  }, [currentContactId, isShowAdobeAnswers, isShowRU, showAdobeAnswers])

  useEffect(() => {
    ruWidgetBottomShow[currentContactId] && dispatch(triggerWidget(widgets, ruWidgetBottom, widgetWrapperWidth, widgetStack))
    SetWidgets()
  }, [ruExpanded])

  useEffect(() => {
    dispatch(closeWidgets(widgets))
    const isSalesCIP = currentQueueVal.substring(0, 4) === 'SLS-'
    const isDNL = currentQueueVal.substring(0, 4) === 'DNL-'

    isSalesCIP || isDNL 
      ? dispatch({ type: SALES_CIP, payload: 417 })
      : dispatch({ type: SALES_CIP, payload: 515 })

    if (conversation?.jcAuthData) {
      const widgetPriority = showRUWidget ? {
        'SLS-s': ['RU', 'SC'],
        'SLS-m': ['RU', 'CH', 'SC'],
        'SLS-l': ['RU', 'CH', 'SC'],
        'CI-xs': ['RU', 'CI'],
        'CI-s': ['RU', 'CH', 'CI'],
        'CI-m': ['RU', 'AA', 'CI'],
        'CI-l': ['RU', 'AA', 'CH', 'CI'],
        'ENT-xs': ['RU', 'CI'],
        'ENT-s': ['RU', 'CH', 'CI'],
        'ENT-m': ['RU', 'AA', 'CI'],
        'ENT-l': ['RU', 'AA', 'CH', 'CI']
      }
        : {
          'SLS-s': ['AA', 'SC'],
          'SLS-m': ['AA', 'CH', 'SC'],
          'SLS-l': ['CH', 'AA', 'SC'],
          'CI-xs': ['CM', 'CI'],
          'CI-s': ['CM', 'CH', 'CI'],
          'CI-m': ['CM', 'AA', 'CI'],
          'CI-l': ['AA', 'CM', 'CH', 'CI'],
          'ENT-xs': ['ECM', 'CI'],
          'ENT-s': ['ECM', 'CH', 'CI'],
          'ENT-m': ['ECM', 'AA', 'CI'],
          'ENT-l': ['AA', 'ECM', 'CH', 'CI']
        }
      if (!showAdobeAnswers) Object.keys(widgetPriority).map((key) => (widgetPriority[key] = widgetPriority[key].filter((w) => w !== 'AA'))) 

      const widgetSelection = JSON.parse(sessionStorage.getItem('widgetSelection') || '{}')[initialContactId] ?? []
      const isWidgetSelection = widgetSelection.length
      const queue = currentQueueVal
      const element = queue ? queue.substring(0, 4) : ''

      if (isWidgetSelection) {
        let widgetSelectionRemoveAA = widgetSelection
        if (!showAdobeAnswers) {
          widgetSelectionRemoveAA = widgetSelectionRemoveAA.filter((w) => w !== 'AA')
        }
        if (!showRUWidget) {
          widgetSelectionRemoveAA = widgetSelectionRemoveAA.filter((w) => w !== 'RU')
        }
        if (element === 'SLS-') {
          widgetSelectionRemoveAA = widgetSelectionRemoveAA.filter((w) => w !== 'CI' && w !== 'ECM' && w !== 'CM')
        } else {
          widgetSelectionRemoveAA = widgetSelectionRemoveAA.filter((w) => w !== 'SC')
        }
        if(widgetSelectionRemoveAA.find(widget => widget ==='RU') && !widgetSelectionRemoveAA.find(widget => widget === determineRuBottomTitle(currentQueueVal))) {
          dispatch({ type: SET_RU_WIDGET_SHOW, payload: {convId: currentContactId, ruWidgetBottomShow: false } })
        }
        widgetSelectionRemoveAA.map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
      }

      if (currentQueueVal || queueNameVal) {
        if (element === 'SLS-') {
          if (!isWidgetSelection) {
            if (widgetWrapperWidth < 1115) {
              widgetPriority['SLS-s'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            } else if (widgetWrapperWidth < 1430) {
              widgetPriority['SLS-m'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            } else {
              widgetPriority['SLS-l'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            }
          }
          const widget = JSON.parse(JSON.stringify(widgets))
            .filter((w: any) => (w.title === 'RU' && showRUWidget) || w.title === 'SC' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
            .sort((a: any, b: any) => {
              if (a.title === 'PDC') return -1
              if (b.title === 'AA') return -1
              if (b.title === 'CH') return -1
              if (b.title === 'SC') return 1
              return 0
            })
          handleSetUserWidgets(widget)
        } else if (element === 'ENT-' || (isZendeskDecommissioned() && element?.toLowerCase() === 'sign')) {
          if (!isWidgetSelection) {
            if (widgetWrapperWidth < 1210) {
              widgetPriority['ENT-xs'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            } else if (widgetWrapperWidth > 1210 && widgetWrapperWidth < 1425) {
              widgetPriority['ENT-s'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            } else if (widgetWrapperWidth > 1425 && widgetWrapperWidth < 1735) {
              widgetPriority['ENT-m'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            } else {
              widgetPriority['ENT-l'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            }
          }
          handleSetUserWidgets(
            JSON.parse(JSON.stringify(widgets))
              .filter((w: any) => (w.title === 'RU' && showRUWidget) || w.title === 'CI' || w.title === 'ECM' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
              .sort((a: any, b: any) => {
                if (a.title === 'PDC') return -1
                if (b.title === 'AA') return -1
                if (b.title === 'CH') return -1
                if (b.title === 'ECM') return 0
                return 1
              })
          )
        } else if (element === 'DNL-') {
          if (!isWidgetSelection) {
            if(showRUWidget) {
              dispatch(triggerWidget(widgets, 'RU', widgetWrapperWidth, widgetStack))
            } else if (showAdobeAnswers) {
              dispatch(triggerWidget(widgets, 'AA', widgetWrapperWidth, widgetStack))
            } 
            dispatch(triggerWidget(widgets, 'CH', widgetWrapperWidth, widgetStack))
            dispatch(triggerWidget(widgets, 'CI', widgetWrapperWidth, widgetStack))
          }
          handleSetUserWidgets(
            JSON.parse(JSON.stringify(widgets))
              .filter((w: any) => (w.title === 'RU' && showRUWidget) || w.title === 'CI' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
              .sort((a: any, b: any) => {
                if (a.title === 'PDC') return -1
                if (b.title === 'PDC') return 1
                if (b.title === 'AA') return -1
                if (b.title === 'CH') return -1
                if (b.title === 'CI') return 0
                return 1
              })
          )
        } else {
          if (!isWidgetSelection) {
            if (widgetWrapperWidth < 1210) {
              widgetPriority['CI-xs'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            } else if (widgetWrapperWidth > 1210 && widgetWrapperWidth < 1425) {
              widgetPriority['CI-s'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            } else if (widgetWrapperWidth > 1425 && widgetWrapperWidth < 1735) {
              widgetPriority['CI-m'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            } else {
              widgetPriority['CI-l'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
            }
          }
          handleSetUserWidgets(
            JSON.parse(JSON.stringify(widgets))
              .filter((w: any) => (w.title === 'RU' && showRUWidget) || w.title === 'CI' || w.title === 'CM' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
              .sort((a: any, b: any) => {
                if (a.title === 'PDC') return -1
                if (b.title === 'AA') return -1
                if (b.title === 'CH') return -1
                if (b.title === 'CM') return 0
                return 1
              })
          )
        }
      } else {
        if (!isWidgetSelection) {
          if (widgetWrapperWidth < 1210) {
            widgetPriority['CI-xs'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
          } else if (widgetWrapperWidth > 1210 && widgetWrapperWidth < 1425) {
            widgetPriority['CI-s'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
          } else if (widgetWrapperWidth > 1425 && widgetWrapperWidth < 1735) {
            widgetPriority['CI-m'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
          } else {
            widgetPriority['CI-l'].map((title) => dispatch(triggerWidget(widgets, title, widgetWrapperWidth, widgetStack)))
          }
        }
        handleSetUserWidgets(
          JSON.parse(JSON.stringify(widgets))
            .filter((w: any) => (w.title === 'RU' && showRUWidget) || w.title === 'CI' || w.title === 'CM' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
            .sort((a: any, b: any) => {
              if (a.title === 'PDC') return -1
              if (b.title === 'AA') return -1
              if (b.title === 'CH') return -1
              if (b.title === 'CM') return 0
              return 1
            })
        )
      }
    }
    const cleanup = () => {
      dispatch(closeWidgets(widgets))
    }
    return cleanup
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContactId, showAdobeAnswers, showRUWidget])

  useEffect(() => {
    SetWidgets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgets])

  let SetWidgets = () => {
    if (conversation?.jcAuthData) {
      if (currentQueueVal || queueNameVal) {
        const queue = currentQueueVal
        const element = queue ? queue.substring(0, 4) : ''
        if (element === 'SLS-')
          handleSetUserWidgets(
            JSON.parse(JSON.stringify(widgets))
              .filter((w: any) => (w.title === 'RU' && showRUWidget) || w.title === 'SC' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
              .sort((a: any, b: any) => {
                if (a.title === 'PDC') return -1
                if (b.title === 'CH') return -1
                if (b.title === 'AA') return -1
                if (b.title === 'SC') return 1
                return 0
              })
          )
        else if (element === 'ENT-' || (isZendeskDecommissioned() && element?.toLowerCase() === 'sign'))
          handleSetUserWidgets(
            JSON.parse(JSON.stringify(widgets))
              .filter((w: any) => (w.title === 'RU' && showRUWidget) || w.title === 'CI' || w.title === 'ECM' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
              .sort((a: any, b: any) => {
                if (a.title === 'PDC') return -1
                if (b.title === 'AA') return -1
                if (b.title === 'CH') return -1
                if (b.title === 'ECM') return 0
                return 1
              })
          )
        else if (element === 'DNL-')
          handleSetUserWidgets(
            JSON.parse(JSON.stringify(widgets))
              .filter((w: any) => (w.title === 'RU' && showRUWidget) || w.title === 'CI' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
              .sort((a: any, b: any) => {
                if (a.title === 'PDC') return -1
                if (b.title === 'CH') return -1
                if (b.title === 'AA') return -1
                if (b.title === 'CI') return 0
                return 1
              })
          )
        else
          handleSetUserWidgets(
            JSON.parse(JSON.stringify(widgets))
              .filter((w: any) => (w.title === 'RU' && showRUWidget) || w.title === 'CI' || w.title === 'CM' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
              .sort((a: any, b: any) => {
                if (a.title === 'PDC') return -1
                if (b.title === 'AA') return -1
                if (b.title === 'CH') return -1
                if (b.title === 'CM') return 0
              })
          )
      } else {
        handleSetUserWidgets(
          JSON.parse(JSON.stringify(widgets))
            .filter((w: any) => (w.title === 'RU' && showRUWidget) || w.title === 'CI' || w.title === 'CM' || (w.title === 'AA' && showAdobeAnswers) || w.title === 'CH' || w.title === 'PDC')
            .sort((a: any, b: any) => {
              if (a.title === 'PDC') return -1
              if (b.title === 'AA') return -1
              if (b.title === 'CH') return -1
              if (b.title === 'CM') return 0
            })
        )
      }
    }
  }

  const handleSetUserWidgets = (widgets: any[]) => {
    const pdcWidgetHideForCallOrigin = [CALLEVENTS.INBOUND_PHONE, CALLEVENTS.OUTBOUND_PHONE, CALLEVENTS.BOT_TO_PHONE]
    if (pdcWidgetHideForCallOrigin.includes(origin)) {
      const widget = widgets?.filter((w: any) => w.title !== 'PDC') || []
      setUserWidgets(widget)
    } else setUserWidgets(widgets)
  }

  function callBackEventListener(eventType: string, eventData: any): void {
    if (eventType === 'autoTranslation') {
      dispatch(setAutoTranslation(eventData))
    } else if (eventType === 'alertManagerNowNegativeSentiment') {
      const { contactId } = eventData
      // optimistically update redux
      const oldAlertState = sentiment?.alertState
      dispatch(setContactRealTimeSentiment({
        contactId,
        sentiment: {
          ...sentiment,
          alertState: 'manager'
        }
      }))
      const previousContactLegInfo = previousLegInfo?.[contactId] || []
      alertManagerNegativeSentimentApi(contactId, ldap, agentFeatureTags?.ManagerLDAP ?? '', currentQueueVal, initialContactId, previousContactLegInfo)
        .then((res) => {
          console.log('Manager alerted. Response: ', res)
        })
        .catch((err) => {
          // reset redux state if error
          console.log('Error during Alert notification. Error: ', err)
          // dispatch(setContactRealTimeSentiment({
          //   contactId,
          //   sentiment: {
          //     ...sentiment,
          //     alertState: oldAlertState
          //   }
          // }))
        })
    } else if (eventType !== 'EXCEPTION') {
      if ( eventData.title === determineRuBottomTitle(currentQueueVal) ) {
        dispatch({ type: COLLAPSE_RU_DETAILS_HANDLER, payload: ruExpanded ? COLLAPSE : ruWidgetBottomShow[currentContactId] ? UNMOUNT_RU_WIDGET_BUTTOM : MOUNT_RU_WIDGET_BUTTOM })
        dispatch({ type: SET_RU_WIDGET_SHOW, payload: {convId: currentContactId, ruWidgetBottomShow: !ruWidgetBottomShow[currentContactId] } })
      } 
      dispatch(triggerWidget(widgets, eventData.title, widgetWrapperWidth, widgetStack))
      SetWidgets()
      // load the existing widget Selection values
      let persistentWidget = JSON.parse(sessionStorage.getItem('widgetSelection') || '{}')
      // Add the opened widget
      const openWidget = JSON.parse(JSON.stringify(widgets))
        .filter((w) => w.active)
        .map((w) => w.title)
      persistentWidget[initialContactId] = openWidget ?? []
      // update stored values
      sessionStorage.setItem('widgetSelection', JSON.stringify(persistentWidget))
    }
  }

  try {
    const defaultHeader = localization[language].TOP_BAR.MY_CONVERSATIONS
    const newCustomer = newCustomerInfo ? 1 : 0
    const convStatus = status || ''
    const convDuration = duration || 0
    const chartStartTime = conversationStartDate
    const updateDate = updateDateInfo || new Date()
    const customerName = ContactCenter.getCustomerName(conversation, defaultHeader)
    const octDuration = Math.round((new Date(updateDate).getTime() - new Date(chartStartTime).getTime()) / 1000) || 0
    const isAcw = (bannerInfo?.isAcw && (bannerInfo?.contactId === currentContactId || bannerInfo?.contactId === callContactId)) || false
    const linkedConversationId = callLinkedConversationId || jcLinkedConversationId || currentContactId
    const contactId = origin === CALLEVENTS.CHAT_TO_PHONE ? linkedConversationId : callContactId || currentContactId
    const timer: any = Utility.getOctAndThisAgentTimer({
      contactId,
      convStatus,
      callStatus,
      origin,
      convDuration,
      octDuration,
      chartStartTime,
      channel,
      updateDate,
      isAcw
    })
    let octTime: number = timer.octTime || 0,
      agentTime: number = timer.agentTime || 0
    const muteTime = callStatus === 'Active' && bannerInfo?.muteStartDate ? Math.round((new Date().getTime() - new Date(bannerInfo.muteStartDate).getTime()) / 1000) || 0 : 0

    const json = {
      widgets: currentContactId ? userWidgets : [],
      lang: language,
      customerLang,
      widgetHeader: customerName,
      darkMode,
      transferCount: newCustomer,
      duration: Number(octTime) || 0,
      status: convStatus,
      currentView,
      selectedContactId: currentContactId,
      agentTime: Number(agentTime) || 0,
      callContactId,
      callStatus,
      autoTranslation,
      origin,
      isAcw,
      muteTime: Number(muteTime) || 0,
      isMute,
      channel
    }
    return (
      <div className='chat-topbar'>
        <WidgetBar enableRTS={enableRTS?.[currentContactId] && !isPhoneOriginOrChatToPhone} sentiments={contactRTSentiments} alertThreshold={triggerThresholdRTS} alertTimeRemainingRTS={(triggerThresholdRTS * 1000) - negativeTimeElapsed[currentContactId]} contextData={json} callBackEvent={callBackEventListener} />
      </div>
    )
  } catch (error) {
    console.log(error)
    return <></>
  }
}

export default TopBar
